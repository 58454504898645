$red: #c0382b;
$link: $red;

.logo-suffix {
    font-weight: bold;
    font-size: 20px;
    color: $red;
    margin-left: 5px;
}

.copy-icon-svg {
    height: 1em;
    width: 0.875em;
}

@import "~bulma/bulma";
